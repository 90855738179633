<route>
{
  "name": "reset-password-step1",
  "meta": {
    "title": "Reset Password",
    "auth": false
  }
}
</route>

<template>
  <div v-if="!isLinkSended" class="flex-column flex-grow">
    <ui-link to="/login">
      <img src="@/assets/images/BackArrow.svg" alt="back-arrow" class="mt-8"/>
    </ui-link>
    <div class="wrapper">
      <ui-form
        class="form"
        :model="values"
        :rules="rules"
        :hideRequiredMark="true"
        @form="form = $event"
        @validate="handleValidate"
        @submit.prevent="handleSubmit"
      >
        <img src="@/assets/images/reset_pass.png" alt="anonymous-icon" class="anonymous-icon mb-24"/>
        <div class="title1 mb-8">{{ $t('reset-password-step1.reset_password') }}</div>
        <div class="text1 mb-32">{{ $t('reset-password-step1.don_t_worry_happens_to_the') }}</div>
        <ui-form-item label="Your E-mail" prop="email">
          <ui-email-input placeholder="ex. example@gmail.com" v-model="values.email" @input="handleValidate"/>
        </ui-form-item>
      </ui-form>
    </div>
    <ui-button @click="handleSubmit" :disabled="btnDisabled">
      {{ $t('reset-password-step1.email_me_a_recover_link') }}
    </ui-button>
  </div>
  <div v-else class="flex-column flex-grow">
    <ui-link to="/login">
      <img src="@/assets/images/BackArrow.svg" alt="back-arrow" class="mt-8"/>
    </ui-link>
    <div class="wrapper">
      <div>
        <img src="@/assets/images/send_mail.png" alt="email-icon" class="anonymous-icon mb-24"/>
        <div class="title1 mb-8">{{ $t('reset-password-step1.reset_password') }}</div>
        <div class="text1">
          An email has been sent to {{ values.email }}. Please, click on the link to reset your password
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import promiseValidateFields from '@/utils/promiseValidateFields';
import { authManagementClient } from '@/plugins/feathersClient';
import { enableAllValidationTriggers } from '../utils/enableAllValidationTriggers';
import UiForm from '../components/ui/UiForm';
import UiFormItem from '../components/ui/UiFormItem';
import UiEmailInput from '../components/ui/UiEmailInput';
import UiButton from '../components/ui/UiButton';
import UiLink from '../components/ui/UiLink';

export default {
  layout: 'auth',
  name: 'reset-password-step1',
  components: {
    UiLink,
    UiForm,
    UiFormItem,
    UiEmailInput,
    UiButton
  },
  data() {
    return {
      form: {},
      values: {
        email: ''
      },
      rules: {
        email: [
          {
            // eslint-disable-next-line no-useless-escape
            pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
            whitespace: true,
            message: 'Please, enter a valid email ',
            trigger: 'blur'
          }
        ]
      },
      btnDisabled: true,
      isLinkSended: false,
      hadValidationError: false
    };
  },
  methods: {
    handleValidate() {
      // prop name of the form item being validated,
      // whether validation is passed and the error message if not
      this.btnDisabled = this.form.fields.reduce((result, field) => {
        const validationState = field.validateState === 'error';
        if (!this.hadValidationError && validationState) {
          this.hadValidationError = true;
          enableAllValidationTriggers(this.rules);
        }

        return !field.fieldValue || validationState || result;
      }, false);
    },

    async handleSubmit(e) {
      e.preventDefault();
      this.isLinkSended = true;
      try {
        await promiseValidateFields(this.form.validate);
        global.ym(process.env.VUE_APP_YANDEX_METRIKA_ID, 'reachGoal', 'send_reset_password');
        await authManagementClient.sendResetPwd({
          email: this.values.email
        });
      } catch (err) {
        console.log('error', err);
        this.$notify.parseFeathersErrors(err);
      }
    },

    resetForm() {
      this.form.resetFields();
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.form {
  width: 100%;
  min-height: 280px;
}

.footer {
  padding: 32px 0 16px 0;
}
</style>
